/* eslint-disable no-unused-vars */ /* eslint-disable no-unused-vars */
<template>
  <div>
    <v-card class="elevation-12">
      <v-toolbar color="info" dark flat>
        <v-toolbar-title>
          <span>Missing Principal Owner for {{ selectedParticipant.organization.name }}</span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-tooltip bottom>
          <template v-slot:activator="{ on: on }">
            <v-btn icon v-on="on" :to="{ name: 'login' }">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </template>
          <span>{{ $i18n.translate("Cancel") }}</span>
        </v-tooltip>
      </v-toolbar>
      <ApiError :errors="errors"></ApiError>
      <v-card-text>
        <v-container>
          <v-row>
            <v-col cols="12">
              <v-radio-group v-model="q1">
                <v-radio
                  label="We are missing your company's principal owner information. Please provide your company's principal owner name and email address."
                  value="1"
                ></v-radio>
                <div v-if="q1 == 1">
                  <v-form v-model="validForm">
                    <v-text-field
                      label="Principal Owner First Name"
                      :rules="[v => !!v || 'First Name is required']"
                      v-model="form.user.firstName"
                    ></v-text-field>
                    <v-text-field
                      label="Principal Owner Last Name"
                      :rules="[v => !!v || 'Last Name is required']"
                      v-model="form.user.lastName"
                    ></v-text-field>
                    <EmailField label="Principal Owner Email" v-model="form.user.email"></EmailField>
                    <v-btn color="primary" @click="enrollPrincipalOwner()" :disabled="!validForm">{{
                      $i18n.translate("Save")
                    }}</v-btn>
                  </v-form>
                  <br />
                </div>

                <v-radio label="I am the principal owner of the company" value="2"></v-radio>
                <v-form>
                  <v-btn v-if="q1 == 2" color="primary" :loading="loading" @click="makeMePrincipalOwner()"
                    >Confirm</v-btn
                  >
                </v-form>
              </v-radio-group>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import EmailField from "../gapp-components/components/fields/EmailField.vue";
import ApiError from "../gapp-components/components/display/ApiError.vue";
import { mapGetters } from "vuex";

export default {
  components: { ApiError, EmailField },
  name: "Register",
  metaInfo: {
    title: "Register"
  },
  data() {
    return {
      q1: null,
      loading: false,
      errors: {},
      validForm: false,
      form: {
        participantStatus: { name: "ENR" },
        participantType: {
          participantTypeKey: 800
        },
        user: {
          firstName: "",
          lastName: "",
          email: "",
          username: ""
        },
        participant: {
          email1: "",
          participantType: {
            participantTypeKey: "800"
          },
          phoneNumber1: "",
          phoneNumber2: "",
          address: {
            address1: "",
            address2: "",
            country: {},
            region: {},
            city: "",
            postalCode: ""
          }
        },
        eintin: "",
        organizationType: {
          organizationTypeKey: "INDIRECT_3PTY_RESELLER"
        },
        website: "",
        phoneNumber1: "",
        phoneNumber2: ""
      },
      relationships: [],
      sendMail: true
    };
  },

  mounted() {},
  computed: {
    ...mapGetters(["selectedParticipant"])
  },
  methods: {
    enrollPrincipalOwner() {
      let postForm = {};
      postForm.participant = this.form.participant;
      postForm.participant.user = this.form.user;
      postForm.participant.user.username = postForm.participant.user.email;
      postForm.participant.email1 = postForm.participant.user.email;
      postForm.participant.organization = this.selectedParticipant.organization;
      postForm.participant.participantType = { participantTypeKey: "800" };

      if (postForm.participant.address) {
        if (postForm.participant.address.region) {
          postForm.participant.address.region = { name: postForm.participant.address.region.name };
        }
        if (postForm.participant.address.country) {
          postForm.participant.address.country = { name: postForm.participant.address.country.name };
        }
      }

      if (postForm.organization.address) {
        if (postForm.organization.address.region) {
          postForm.organization.address.region = { name: postForm.organization.address.region.name };
        }
        if (postForm.organization.address.country) {
          postForm.organization.address.country = { name: postForm.organization.address.country.name };
        }
      }

      postForm.emailTemplateParticipantForm = {
        emailTemplateKey: "REGISTER_WEBSITE_PRINCIPAL_INDIRECT_DESIGNATE"
      };
      this.$api.post("/api/participants/enroll", this.form).then(() => {
        this.$router.push({ name: "dashboard" });
      });
    },
    makeMePrincipalOwner() {
      this.$api.post("/api/participantTypes/search?size=1", { participantTypeKey: 800 }).then(({ data }) => {
        this.$api
          .patch("/api/participants/" + this.selectedParticipant.id, {
            participantType: this.$api.getSelfUrl("participantType", data.content[0])
          })
          .then(({ data }) => {
            this.$auth.storeSelectParticipant(data).then(() => {
              this.$auth.storeAvailableParticipants().then(() => {
                this.$router.push({ name: "dashboard" });
              });
            });
          });
      });
    }
  }
};
</script>
